import { Group, Select, SelectItemProps, Text } from '@mantine/core';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { fetchPatients } from '../fhirApi';
import { useForm } from '@mantine/form';
import { IconSearch } from '@tabler/icons-react';

interface AutoCompleteSearchProps {
  setPatients: (patients: { label: string; value: string; dob?: string }[]) => void;
  setSelectedPatient: (item: string) => void;
  selectedPatient: string;
  patients: { label: string; value: string; dob?: string }[];
  searchInputRef: React.RefObject<HTMLInputElement>;
}

interface FormValues {
  name: string;
  dob: Date | null;
}

interface ItemProps extends SelectItemProps {
  value: string;
  label: string;
  dob?: string;
}

const defaultQuery = (searchTerm: string): string => `{
  PatientList(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    birthDate
    name {
      family
      given
    }
  }
}`;

const AutoCompleteSearch = ({
  setPatients,
  setSelectedPatient,
  selectedPatient,
  patients,
  searchInputRef,
}: AutoCompleteSearchProps): JSX.Element => {
  const medplum = useMedplum();
  const [data, setData] = useState<ItemProps[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      dob: null,
    },
  });

  useEffect(() => {
    const mappedData = patients.map((patient) => ({
      value: patient.value,
      label: patient.label,
      dob: patient?.dob,
    }));
    setData(mappedData);
  }, [patients]);

  useEffect(() => {
    if (searchTerm) {
      const query = defaultQuery(searchTerm);

      fetchPatients(medplum, query)
        .then((response: any) => {
          const patientList = response.data.PatientList.map((patient: any) => {
            const givenName = patient?.name?.[0]?.given ? patient?.name?.[0]?.given.join(' ') : '';
            const familyName = patient?.name?.[0]?.family || '';
            const label = familyName ? `${givenName} ${familyName}` : givenName;

            return {
              value: patient.id,
              label: label,
              dob: patient.birthDate,
            };
          });
          setPatients(patientList);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    } else {
      setPatients([]);
    }
  }, [searchTerm, medplum, setPatients]);

  const previousPatientRef = useRef<string | null>(null);
  useEffect(() => {
    if (selectedPatient && selectedPatient !== previousPatientRef.current) {
      previousPatientRef.current = selectedPatient;
      const patient = patients.find((p) => p.value === selectedPatient);
      if (patient) {
        form.setValues({
          name: patient.label,
          dob: patient.dob ? new Date(patient.dob) : null,
        });
        setSearchTerm(patient.label);
      }
    }
  }, [selectedPatient, patients, form]);

  const ItemComponent = forwardRef<HTMLDivElement, ItemProps>(({ label, dob, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} style={{ ...others.style, backgroundColor: '#F8FAFC', color: 'black' }}>
      <Group noWrap>
        <ResourceAvatar />
        <div>
          <Text>{label}</Text>
          <Text size="xs" color="black">
            {dob}
          </Text>
        </div>
      </Group>
    </div>
  ));


  return (
    <div>
      <Select
        ref={searchInputRef}
        placeholder="Search Patient"
        data={data}
        value={selectedPatient}
        searchValue={searchTerm}
        searchable
        clearable
        icon={<IconSearch size={17} style={{ marginTop: '2px' }} />}
        onChange={(value: string) => {
          if (value !== null) {
            setSelectedPatient(value);
            const selectedItem = data?.find((item) => item?.value === value);
            if (selectedItem) {
              setSearchTerm(selectedItem.label);
            }
          }
        }}
        onSearchChange={(value: string) => {
          setSearchTerm(value);
          if (value === '') {
            setPatients([]);
            setSelectedPatient('');
          }
        }}
        itemComponent={ItemComponent}
        filter={(value, item) =>
          item?.label?.toLowerCase()?.includes(value?.toLowerCase()?.trim()) || item?.dob?.includes(value?.trim())
        }
        sx={{
          inputWrapper: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          },
          input: {
            width: '250px',
            transition: 'width 0.2s',
            marginBottom: '4px',
            borderRadius: '8px',
            '&:focus-within': {
              width: '350px',
            },
          },
          root: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
          },
          icon: {
            marginRight: '8px',
            verticalAlign: 'middle',
          },
          cursor: 'text',
          '.mantine-1gza2dz': {
            display: 'none',
          },
        }}
      />
    </div>
  );
};

export default AutoCompleteSearch;
